import React from "react"

import { DMButtonNeutral, DMIcon } from "@supernovaio/dm"
import { DMButtonBaseProps } from "@supernovaio/dm/src/components/primitives/Button/Button.types"
import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconAlert, IconError, IconInfo } from "@supernovaio/icons"
import { IconType } from "@supernovaio/icons/types"

import { cva } from "class-variance-authority"

const barVariants = cva(["flex justify-between items-center border-b gap-8"], {
  variants: {
    size: {
      small: "h-icon-large py-12 px-16",
      medium: "h-site-header p-16 text-body",
    },
    variant: {
      info: "bg-primary-faded border-primary-faded",
      error: "bg-critical-faded border-critical-faded",
      warn: "bg-warning-faded border-warning-faded",
      blue: "bg-decorative-blue border-neutral-faded",
      yellow: "bg-decorative-yellow border-neutral-faded",
      pink: "bg-decorative-pink border-neutral-faded",
      green: "bg-decorative-green border-neutral-faded",
      "light-grey": "bg-decorative-light-grey border-neutral-faded",
    },
    type: {
      default: "",
      notch: "rounded-b mx-12 border-r border-l",
    },
  },
  defaultVariants: {
    size: "medium",
    variant: "info",
    type: "default",
  },
})

const textVariant = cva(undefined, {
  variants: {
    variant: {
      info: "text-primary",
      error: "text-critical",
      warn: "text-warning",
      blue: "text-primary",
      yellow: "text-primary",
      pink: "text-primary",
      green: "text-primary",
      "light-grey": "text-primary",
    },
  },
  defaultVariants: {
    variant: "info",
  },
})

const variantIcons = {
  info: IconInfo,
  error: IconError,
  warn: IconAlert,
  blue: undefined,
  yellow: undefined,
  pink: undefined,
  green: undefined,
  "light-grey": undefined,
}

type Size = "small" | "medium"
type Variant =
  | "info"
  | "error"
  | "warn"
  | "blue"
  | "yellow"
  | "pink"
  | "green"
  | "light-grey"

type ActionButtonProps = {
  text: string
  icon?: IconType
} & Partial<
  Pick<
    DMButtonBaseProps,
    | "isDisabled"
    | "isLoading"
    | "isExternalLink"
    | "href"
    | "onClick"
    | "rel"
    | "target"
    | "variant"
    | "size"
  >
>

function ActionButton({
  text,
  icon: Icon,
  href,
  target,
  variant = "ghost",
  ...rest
}: ActionButtonProps) {
  return (
    <DMButtonNeutral
      as={href ? "a" : undefined}
      href={href}
      variant={variant}
      icon={Icon ? <Icon /> : undefined}
      // In case `href` is provided but not `target` use `_blank` by default
      target={target ?? href ? "_blank" : undefined}
      {...rest}
    >
      {text}
    </DMButtonNeutral>
  )
}

type ActionProps = Omit<ActionButtonProps, "size">

export type DMTopBarProps = {
  size?: Size
  variant?: Variant
  type?: "default" | "notch"
  children?: React.ReactNode
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  primaryActionProps?: ActionProps
  showIcon?: boolean
  secondaryActionProps?: ActionProps
  tertiaryAction?: React.ReactNode
  className?: string
  customPrimaryAction?: React.ReactNode
  customSecondaryAction?: React.ReactNode
}

export function DMTopBar({
  size = "medium",
  variant = "info",
  type = "default",
  children,
  icon,
  showIcon = true,
  primaryActionProps,
  secondaryActionProps,
  tertiaryAction,
  className,
  customPrimaryAction,
  customSecondaryAction,
}: DMTopBarProps) {
  const Icon = showIcon ? icon || variantIcons[variant] : undefined

  return (
    <div className={cn(barVariants({ size, variant, type }), className)}>
      <div className="flex gap-8 items-center min-w-0">
        {Icon && <DMIcon svg={<Icon />} className={textVariant({ variant })} />}
        {typeof children === "string" || typeof children === "number" ? (
          <span className="text-black truncate">{children}</span>
        ) : (
          children
        )}
      </div>
      <div className="flex gap-8 items-center">
        {customPrimaryAction}
        {primaryActionProps && !customPrimaryAction && (
          <ActionButton size={size} {...primaryActionProps} />
        )}
        {customSecondaryAction}
        {secondaryActionProps && !customSecondaryAction && (
          <ActionButton size={size} {...secondaryActionProps} />
        )}
        {tertiaryAction}
      </div>
    </div>
  )
}
