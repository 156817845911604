import React from "react"

import {
  DMButtonNeutral,
  DMTooltip,
  DropdownMenu,
  DropdownMenuTrigger,
} from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

import {
  IconArrowDown,
  IconArrowUp,
  IconInfo,
  IconMore,
} from "@supernovaio/icons"

import { DropdownMenuProps } from "@radix-ui/react-dropdown-menu"

import { DMIcon } from "../../DMIcon"
import DMDataTableTd from "../DMDataTableTd"
import DMDataTableTh, { ThProps } from "../DMDataTableTh"

export type HeaderCellProps = ThProps & {
  value?: string
  infoTooltip?: {
    text?: string
    textSecondary?: string
  }
  alignment?: "left" | "center" | "right"
  dropdownMenuContent?: React.ReactNode
  extraContent?: React.ReactNode
  width?: number
  stickToLeft?: boolean
  valueIcon?: React.ReactNode
  sorted?: "asc" | "desc" | false

  shouldHighlightOnHover?: boolean
}

function ClickableCellWithDropdown({
  children,
  dropdownMenuContent,
  ...props
}: {
  children: React.ReactElement
  dropdownMenuContent: React.ReactNode
} & Omit<DropdownMenuProps, "children">) {
  const insideClick = React.useRef(false)
  const [open, setOpen] = React.useState(false)

  const onClickAnywhere = React.useCallback(() => {
    if (!insideClick.current) {
      setOpen(false)
    }

    insideClick.current = false
  }, [])

  React.useEffect(() => {
    document.addEventListener("click", onClickAnywhere)
    return () => document.removeEventListener("click", onClickAnywhere)
  }, [onClickAnywhere])

  return (
    <span
      onClick={() => setOpen(true)}
      onMouseDown={() => {
        insideClick.current = true
      }}
    >
      {children}
      {dropdownMenuContent && (
        <DropdownMenu {...props} open={open}>
          <DropdownMenuTrigger>
            <div />
          </DropdownMenuTrigger>
          {dropdownMenuContent}
        </DropdownMenu>
      )}
    </span>
  )
}

function ClickableCell({
  clickableCell,
  children,
  dropdownMenuContent,
  ...props
}: {
  children: React.ReactElement
  clickableCell?: boolean
  dropdownMenuContent: React.ReactNode
} & Omit<DropdownMenuProps, "children">) {
  if (clickableCell && dropdownMenuContent) {
    return (
      <ClickableCellWithDropdown
        dropdownMenuContent={dropdownMenuContent}
        {...props}
      >
        {children}
      </ClickableCellWithDropdown>
    )
  }

  return children
}

const DMDataTableHeaderCell = React.forwardRef<
  HTMLTableCellElement,
  HeaderCellProps
>(
  (
    {
      value,
      infoTooltip,
      alignment = "left",
      dropdownMenuContent,
      extraContent,
      width,
      stickToLeft,
      valueIcon,
      sorted,
      shouldHighlightOnHover,
      ...props
    },
    ref
  ) => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false)
    const clickableCell = false

    return (
      <DMDataTableTh
        {...props}
        className={cn(
          "bg-blur-neutral-faded h-table-header-cell hover:bg-blur-neutral-highlighted group/cell relative z-10",
          stickToLeft && "bg-blur-neutral-faded sticky left-0 z-20 ",
          isMenuOpen && "bg-blur-neutral-highlighted"
        )}
        style={{
          ...props.style,
          width: width || props.style?.width || "auto",
          maxWidth: width || props.style?.width || "none",
          minWidth: width || props.style?.width || 0,
        }}
      >
        <div className="h-table-header-cell flex w-full">
          <div ref={ref} style={{ flex: 1 }} className="min-w-[0px]">
            <ClickableCell
              clickableCell={clickableCell}
              dropdownMenuContent={dropdownMenuContent}
              onOpenChange={(open: boolean) => setIsMenuOpen(open)}
            >
              <DMDataTableTd
                asDiv
                className={cn(
                  "bg-blur-neutral-faded h-table-header-cell px-table-cell flex items-center py-0 group-first/cell:pl-[28px]",
                  clickableCell && "cursor-pointer",
                  shouldHighlightOnHover && "hover:bg-blur-neutral-highlighted"
                )}
                stickToLeft={stickToLeft}
              >
                <div
                  className={cn(
                    "flex w-full min-w-0 items-center gap-8 pr-4",
                    alignment === "left" && "justify-between",
                    alignment === "center" && "justify-center",
                    alignment === "right" && "justify-end"
                  )}
                >
                  <div className="flex items-start gap-4 overflow-hidden">
                    <p
                      className={cn(
                        "text-body-small text-neutral-faded group-first/cell:!text-neutral inline-block truncate font-semibold",
                        shouldHighlightOnHover &&
                          "group-hover/cell:text-neutral"
                      )}
                    >
                      {value}
                    </p>
                    {valueIcon}
                    {sorted &&
                      (sorted === "asc" ? (
                        <IconArrowUp className="text-neutral-faded" />
                      ) : (
                        <IconArrowDown className="text-neutral-faded" />
                      ))}
                  </div>

                  {(dropdownMenuContent || infoTooltip) && (
                    <span
                      className={cn(
                        "px-table-cell absolute right-0 top-0 flex gap-4 h-full items-center",
                        "from-80% to-100% group-hover/cell:bg-gradient-to-l",
                        // This is here to make sure the `i` icon and the context menu buttons are not overlapping the text.
                        // The color has to match the background of the cell
                        shouldHighlightOnHover
                          ? "from-[var(--rs-color-background-blur-neutral-highlighted)] to-[rgba(var(--rs-color-rgb-background-blur-neutral-highlighted),0)]"
                          : "from-[var(--rs-color-background-blur-neutral-faded)] to-[rgba(var(--rs-color-rgb-background-blur-neutral-faded),0)]",
                        isMenuOpen && "bg-gradient-to-l"
                      )}
                    >
                      {infoTooltip && (
                        <DMTooltip
                          text={infoTooltip.text}
                          textAlign="left"
                          textSecondary={
                            infoTooltip.textSecondary &&
                            infoTooltip.textSecondary !== ""
                              ? infoTooltip.textSecondary
                              : "No description"
                          }
                          className={cn("invisible group-hover/cell:visible", {
                            visible: isMenuOpen,
                          })}
                        >
                          <DMIcon color="neutral-faded" svg={IconInfo} />
                        </DMTooltip>
                      )}
                      {dropdownMenuContent && (
                        <DropdownMenu
                          onOpenChange={(open: boolean) => setIsMenuOpen(open)}
                        >
                          <DropdownMenuTrigger
                            asChild
                            className={cn(
                              "invisible group-hover/cell:visible",
                              {
                                visible: isMenuOpen,
                              }
                            )}
                          >
                            <DMButtonNeutral
                              dataTestId="header_cell_context_menu_button"
                              icon={IconMore}
                              size="small"
                              variant="ghost"
                              isHighlighted={isMenuOpen}
                            />
                          </DropdownMenuTrigger>
                          {dropdownMenuContent}
                        </DropdownMenu>
                      )}
                    </span>
                  )}
                </div>
              </DMDataTableTd>
            </ClickableCell>
          </div>
          {extraContent}
        </div>
      </DMDataTableTh>
    )
  }
)

DMDataTableHeaderCell.displayName = "HeaderCell"

export default DMDataTableHeaderCell
